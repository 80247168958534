<template>
  <div>
    <Toolbar class="mb-4">
      <template #right>Neue hinzufügen</template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/blog')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12 text-center">
        <!-- <img
          :src="body.image"
          v-if="id"
          class="profile-pic height-150 width-150 align-items-center"
          style="width: 100px; height: 100px"
        /> -->
        <!-- @error="$errorHandler" -->
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="code" class="form-label required">
            S.N
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="code"
            v-model="body.code"
            :class="{ 'is-invalid': isInvalid && !body.code }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="name" class="form-label required">
            Name
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="body.pname"
            :class="{ 'is-invalid': isInvalid && !body.pname }"
            required
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="purchasing" class="form-label required">
            Kaufpreis
            <span>*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="purchasing"
            v-model.number="body.purchasing"
            :class="{ 'is-invalid': isInvalid && !body.purchasing }"
            required
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="price" class="form-label required">
            Verkaufspreis
            <span>*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="price"
            v-model.number="body.price"
            :class="{ 'is-invalid': isInvalid && !body.price }"
            required
          />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="size" class="form-label required">
            Größe
            <span>*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="size"
            v-model.number="body.size"
            :class="{ 'is-invalid': isInvalid && !body.size }"
            required
          />
        </div>
      </div>

      <!-- <div class="col-md-6">
        <div class="mb-3">
          <label for="Image" class="form-label required">
            Bild
            <span>*</span>
          </label>

          <div class="align-items-center">
            <div class="profile-img-edit align-items-center">
              <div class="p-image">
                <div class="position-relative">
                  <i class="ri-pencil-line upload-button"></i>
                  <b-form-file
                    class="h-100 position-absolute"
                    accept="image/*"
                    @change="previewImage"
                  ></b-form-file>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-md-6">
        <div class="mb-3">
          <label for="categoriesId" class="form-label required"> Kategorie </label>

          <select
            class="form-select form-control"
            id="categoriesId"
            name="categoriesId"
            v-model.number="body.categoriesId"
            :class="{ 'is-invalid': isInvalid && !body.categoriesId }"
            required
          >
            <option v-for="i in catList" :key="i.id" :value="i.id">
              {{ JSON.parse(i.name).de }}
            </option>
          </select>
        </div>
      </div> -->

      <!-- <div class="col-md-6">
        <div class="mb-3">
          <label for="bodyEn" class="form-label required">Körper(EN) </label>

          <Editor v-model="body.body.en" editorStyle="height: 320px" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="bodyDe" class="form-label required">Körper(DE) </label>

          <Editor v-model="body.body.de" editorStyle="height: 320px" />
        </div>
      </div> -->

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="Bearbeiten"
            icon="pi pi-pencil"
            class="ml-2 p-button-info"
            @click="update()"
            v-if="id"
            :disabled="disabledAdd"
          />
          <Button
            v-else
            label="Hinzufügen"
            icon="pi pi-plus"
            class="ml-2 p-button-success"
            @click="save()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        code: null,
        pname: null,
        body: { en: null, de: null },
        purchasing: 0,
        price: 0,
        size: 0,

        categoriesId: null,

        image: null,
      },
      id: null,

      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      catList: [],
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },
    save() {
      if (this.body.price && this.body.code && this.body.pname) {
        this.disabledAdd = true;
     
        
        this.$http.post(`products`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/blog");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.price && this.body.code && this.body.pname) {
        this.disabledAdd = true;

        let body = { ...this.body };

        if (body.image.startsWith("http")) {
          delete body.image;
        }
        if (body.image == null) {
          delete body.image;
        }
        this.$http.put(`products/${this.id}`, body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/blog");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`categories?cattype=1&linkOrSection=section`).then((res) => {
      this.catList = res.data.data;
    });

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`products/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
          this.body.categoriesId = this.body.categoriesId.id;

          this.body.image = this.$baseUploadURL + this.body.image;
          // this.body.name = JSON.parse(this.body.name);
          this.body.body = JSON.parse(this.body.body);
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
